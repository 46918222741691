import clsx from "clsx";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { ArticleTeaserHeading } from "@/components/ArticleTeaserHeading/ArticleTeaserHeading.component";
import { Badge } from "@/components/Badge/Badge.component";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { ArticleTeaserImage } from "@/components/images/ArticleTeaserImage/ArticleTeaserImage.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import { Translation } from "@/components/Translation/Translation.component";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
export type ImageVisibility = "hidden" | "lg:hidden" | "xl:hidden";
export type LeadVisibility = "hidden" | "lg:block" | "xl:block";
type TallFocusArticleTeaserProps = {
  readonly article?: ArticleTeaserModel;
  readonly className?: string;
  readonly hasImagePriority?: boolean;
  readonly trackingId?: string | null;
  readonly size?: "sm" | "md" | "lg";
  readonly imageVisibility?: ImageVisibility;
  readonly leadVisibility?: LeadVisibility;
};
export function TallFocusArticleTeaser({
  className,
  article,
  trackingId,
  hasImagePriority,
  imageVisibility,
  leadVisibility = "lg:block"
}: TallFocusArticleTeaserProps) {
  if (!article) {
    return null;
  }
  const {
    targetUrl,
    id,
    supportingImage,
    headline,
    lead
  } = article.content;
  const {
    requiresSubscription
  } = article;
  return <article className={clsx(className, "z-0 flex h-full flex-col")} data-sentry-component="TallFocusArticleTeaser" data-sentry-source-file="TallFocusArticleTeaser.component.tsx">
      {supportingImage !== null ? <div className={clsx("relative aspect-4/3 overflow-hidden", imageVisibility)}>
          <ArticleTeaserImage alternativeText={supportingImage.alternativeText} hasImagePriority={hasImagePriority} sourceSetSize={`(max-width: ${tailwindScreenSize.md}) 100vw, 300px`} src={supportingImage.aspectRatio4By3} targetUrl={targetUrl} />
          <div className="pointer-events-none absolute bottom-0 flex w-full flex-col gap-y-1 bg-gradient-to-b from-transparent to-nero p-2 pt-4 lg:pb-3" />
        </div> : null}

      <div className="grow bg-nero p-2 text-white lg:pb-3">
        <SnowplowLink href={targetUrl} testId={requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
        articleId: id,
        trackingId: trackingId ?? null,
        url: targetUrl
      }} data-sentry-element="SnowplowLink" data-sentry-source-file="TallFocusArticleTeaser.component.tsx">
          <Badge className={clsx("relative z-10 mb-2", imageVisibility !== "hidden" && supportingImage !== null && "-mt-4", imageVisibility === "lg:hidden" && "lg:mt-0", imageVisibility === "xl:hidden" && "xl:mt-0")} data-sentry-element="Badge" data-sentry-source-file="TallFocusArticleTeaser.component.tsx">
            <Translation da="Fokus" de="Ausgewählt" en="Featured" no="Fokus" sv="I fokus" data-sentry-element="Translation" data-sentry-source-file="TallFocusArticleTeaser.component.tsx" />
          </Badge>

          <ArticleTeaserHeading className="text-3xl font-theme-bold hover:underline lg:text-2xl" title={headline.html} data-sentry-element="ArticleTeaserHeading" data-sentry-source-file="TallFocusArticleTeaser.component.tsx" />
        </SnowplowLink>

        {lead !== null ? <HtmlContent className={clsx(leadVisibility, "hidden text-base leading-snug text-white lg:mt-2")} html={lead.html} /> : null}

        {requiresSubscription ? <RequiresSubscriptionLabel className="mt-2 text-white lg:mt-3" /> : null}
      </div>
    </article>;
}