import { clsx } from "clsx";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { LargeFocusArticleTeaser } from "@/components/ArticleTeaser/FocusArticleTeaser/LargeFocusArticleTeaser.component";
import { ArticleTeaserHeading } from "@/components/ArticleTeaserHeading/ArticleTeaserHeading.component";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { ArticleTeaserImage } from "@/components/images/ArticleTeaserImage/ArticleTeaserImage.component";
import { RelatedArticles } from "@/components/RelatedArticles/RelatedArticles.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { SectionLink } from "@/components/SectionLink/SectionLink.component";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
type LargeArticleTeaserProps = {
  readonly article?: ArticleTeaserModel;
  readonly className?: string;
  readonly hasImagePriority?: boolean;
  readonly imagePosition?: "above" | "after" | "before";
  readonly imageSourceSetSize?: string;
  readonly trackingId?: string | null;
};
export function LargeArticleTeaser({
  className,
  article,
  imagePosition = "before",
  trackingId,
  imageSourceSetSize = `(max-width: ${tailwindScreenSize.md}) 90vw, 560px`,
  hasImagePriority
}: LargeArticleTeaserProps) {
  if (!article) {
    return null;
  }
  const hasImage = article.content.supportingImage !== null;
  const isFeature = Boolean(article.content.isFeature);
  return isFeature ? <LargeFocusArticleTeaser article={article} hasImagePriority={hasImagePriority} renderRelatedArticles={articles => <RelatedArticles relatedArticles={articles} type="bullet" isInverted />} trackingId={trackingId} /> : <article className={clsx(className, "h-full pb-3 text-charcoal lg:pb-4")}>
      <div className="grid auto-rows-auto grid-cols-1 gap-2 lg:grid-cols-12">
        {hasImage ? <div className={clsx(imagePosition === "after" && "lg:order-2", imagePosition !== "above" && "lg:col-span-7", "relative col-span-full")}>
            <div className="aspect-4/3">
              <ArticleTeaserImage alternativeText={article.content.supportingImage.alternativeText} hasImagePriority={hasImagePriority} sourceSetSize={imageSourceSetSize} src={article.content.supportingImage.aspectRatio4By3} targetUrl={article.content.targetUrl} />
            </div>
          </div> : null}

        <div className={clsx(hasImage && imagePosition !== "above" && "lg:col-span-5", imagePosition === "after" && "lg:order-1", "col-span-full")}>
          {article.content.section !== null ? <SectionLink className="mb-2" targetUrl={article.content.section.targetUrl}>
              {article.content.section.name}
            </SectionLink> : null}

          <SnowplowLink className="block hover:underline" href={article.content.targetUrl} testId={article.requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
          articleId: article.content.id,
          trackingId: trackingId ?? null,
          url: article.content.targetUrl
        }}>
            <ArticleTeaserHeading className="text-4xl font-theme-bold lg:text-5xl" title={article.content.headline.html} />
          </SnowplowLink>

          {article.content.lead !== null ? <SnowplowLink href={article.content.targetUrl} testId={article.requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
          articleId: article.content.id,
          trackingId: trackingId ?? null,
          url: article.content.targetUrl
        }}>
              <HtmlContent className="mt-2 text-base leading-snug text-dim transition-colors duration-150 hover:text-black lg:mt-2" html={article.content.lead.html} />
            </SnowplowLink> : null}

          {article.content.relatedArticles.length > 0 ? <RelatedArticles className="mt-2" relatedArticles={article.content.relatedArticles} type="bullet" /> : null}

          {article.requiresSubscription ? <RequiresSubscriptionLabel className="mt-2 text-primary lg:mt-3" /> : null}
        </div>
      </div>
    </article>;
}