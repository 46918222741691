import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { ArticleTeaserListItem } from "@/components/ArticleTeaser/ArticleTeaserListItem/ArticleTeaserListItem.component";
type ArticleTeaserListProps = {
  readonly items: ReadonlyArray<ArticleTeaserModel>;
  readonly positionOffset?: number;
};
export function ArticleTeaserList({
  items,
  positionOffset
}: ArticleTeaserListProps) {
  return <section className="space-y-3 *:border-b *:border-b-silver [&>*:last-child]:border-b-0" data-sentry-component="ArticleTeaserList" data-sentry-source-file="ArticleTeaserList.component.tsx">
      {items.map((item, index) => <div className="pb-3" key={item.content.targetUrl}>
          <ArticleTeaserListItem model={item} position={`article-${index + (positionOffset ?? 1)}`} />
        </div>)}
    </section>;
}