import { clsx } from "clsx";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { TallFocusArticleTeaser } from "@/components/ArticleTeaser/FocusArticleTeaser/TallFocusArticleTeaser.component";
import { ArticleTeaserHeading } from "@/components/ArticleTeaserHeading/ArticleTeaserHeading.component";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { ArticleTeaserImage } from "@/components/images/ArticleTeaserImage/ArticleTeaserImage.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { SectionLink } from "@/components/SectionLink/SectionLink.component";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
type TallArticleTeaserProps = {
  readonly article?: ArticleTeaserModel;
  readonly className?: string;
  readonly hasImagePriority?: boolean;
  readonly imageSourceSetSize?: string;
  readonly imageVisibility?: "hidden";
  readonly leadVisibility?: "hidden" | "lg:block" | "xl:block";
  readonly trackingId?: string | null;
  readonly position?: string;
};
export function TallArticleTeaser({
  className,
  article,
  hasImagePriority,
  imageSourceSetSize = `(max-width: ${tailwindScreenSize.md}) 40vw, 300px`,
  imageVisibility,
  leadVisibility = "lg:block",
  trackingId,
  position
}: TallArticleTeaserProps) {
  if (!article) {
    return null;
  }
  const isFeature = Boolean(article.content.isFeature);
  const isSupportingImageVisible = article.content.supportingImage !== null && imageVisibility !== "hidden";
  const hasSectionLink = article.content.section !== null;
  return isFeature ? <TallFocusArticleTeaser article={article} hasImagePriority={hasImagePriority} imageVisibility={imageVisibility} leadVisibility={leadVisibility} trackingId={trackingId} /> : <article className={clsx(className, "h-full pb-2 lg:pb-3")} data-k5a-pos={position}>
      <div className="grid grid-cols-5 gap-x-1 lg:grid-cols-1 lg:gap-x-0">
        {article.content.supportingImage !== null ? <div className={clsx(imageVisibility === "hidden" && "lg:hidden", "relative col-span-2 row-span-full aspect-4/3 lg:col-span-full lg:row-span-1 lg:mb-1")}>
            <ArticleTeaserImage alternativeText={article.content.supportingImage.alternativeText} hasImagePriority={hasImagePriority} sourceSetSize={imageSourceSetSize} src={article.content.supportingImage.aspectRatio4By3} targetUrl={article.content.targetUrl} />
          </div> : null}

        <div className="col-span-3 lg:col-span-full">
          {hasSectionLink ? <SectionLink className={clsx("hidden lg:block", isSupportingImageVisible ? "lg:my-1" : "lg:mb-1")} targetUrl={article.content.section.targetUrl}>
              {article.content.section.name}
            </SectionLink> : null}

          <SnowplowLink className={clsx(hasSectionLink && "lg:mt-1", "block hover:underline")} href={article.content.targetUrl} testId={article.requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
          articleId: article.content.id,
          trackingId: trackingId ?? null,
          url: article.content.targetUrl
        }}>
            <ArticleTeaserHeading className="text-xl font-theme-bold lg:text-2xl" title={article.content.headline.html} />
          </SnowplowLink>

          {leadVisibility !== "hidden" && article.content.lead !== null ? <SnowplowLink className={leadVisibility} href={article.content.targetUrl} trackingModel={{
          articleId: article.content.id,
          trackingId: trackingId ?? null,
          url: article.content.targetUrl
        }}>
              <HtmlContent className={clsx(leadVisibility, "hidden leading-snug text-dim transition-colors duration-150 hover:text-black lg:mt-2")} html={article.content.lead.html} />
            </SnowplowLink> : null}

          {article.requiresSubscription ? <RequiresSubscriptionLabel className="mt-2 text-primary lg:mt-3" /> : null}
        </div>
      </div>
    </article>;
}